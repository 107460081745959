import React, { lazy } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer'
import { ToastContainer } from 'react-toastify'
import { IsAuthenticated } from './utils/authService'

const Layout = lazy(() => import('./containers/Layout'))
const Login = lazy(() => import('./pages/Login'))
// const CreateAccount = lazy(() => import('./pages/CreateAccount'))
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'))
const AuthGuard = lazy(() => import('./components/AuthGuard/AuthGuard'))
const ResetPassword = lazy(() => import('./pages/ResetPassword'));



function App() {
  return (
    <>
      <Router>
        <AccessibleNavigationAnnouncer />
        <Switch>
          {/* <Route path="/login" 
            component={Login}
          /> */}
          <Route 
            path="/login" 
            render={(props) => (
              +
              IsAuthenticated() ? <Redirect to="/app" /> : <Login />
            )} 
          />
          {/* <Route path="/create-account" component={CreateAccount} /> */}
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password" component={ResetPassword} />

          {/* Place new routes over this */}
          {/* <Route path="/app" component={Layout} /> */}
          <Route 
            path="/app" 
            render={(props) => (
              <AuthGuard protectedPath={true}>
                <Layout {...props} />
              </AuthGuard>
            )} 
          />
          {/* If you have an index page, you can remothis Redirect */}
          <Redirect to="/login" />
        </Switch>
      </Router>
      <ToastContainer />
    </>
  )
}

export default App